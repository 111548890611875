import { gql, useQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryJobPostArgs } from '../../generated/types';

export const GET_JOB_POST = gql`
  query jobPost($userUuid: String!, $jobUuid: String!, $includeDeleted: Boolean) {
    jobPost(userUuid: $userUuid, jobUuid: $jobUuid, includeDeleted: $includeDeleted) {
      uuid
      userUuid

      status

      isThroughAgency
      agencyName
      agentName
      agentEmail
      agentPhone
      referralFee

      company
      companyInfo
      companyWebsite
      companyLinkedIn
      companyAvatar
      companyLocation
      companyLocationCity
      companyLocationCountry
      companyLocationLatitude
      companyLocationLongitude
      companyLocationPostCode
      companyLocationStreet
      companyLocationUrl

      jobTitle
      jobSeniorityLevel
      jobDescription
      jobRequirement
      jobUrl

      ir35
      duration
      rate
      employmentType
      remoteOption

      files {
        uuid
        filename
        size
        mimetype
        formattedMimetype
        resource
        createdAt
        updatedAt
        isDeleted
        temporaryUrl
      }

      contacts {
        uuid
        fullName
        position
        email
        phoneNumber
        linkedin
      }

      createdBy {
        uuid
        email
        nickname
        name
      }

      updatedBy {
        uuid
        email
        nickname
        name
      }

      isOwner

      aiRequiredSkills
      aiSoftSkills
      aiNiceToHaveSkills

      createdAt
      updatedAt
      expiredAt

      permissions

      isDeleted
    }
  }
`;

export const useGetJobPost = (options?: QueryHookOptions<Pick<Query, 'jobPost'>, QueryJobPostArgs>) =>
  useQuery<Pick<Query, 'jobPost'>, QueryJobPostArgs>(GET_JOB_POST, options);
